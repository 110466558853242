"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTableHeaders = exports.DefaultImportHeaders = exports.DefaultImportFormulae = void 0;
exports.DefaultImportFormulae = {
    ggr: '{{bets}} - {{wins}}',
    ngr: '{{ggr}} - {{fees}} - {{bonus}}'
};
exports.DefaultImportHeaders = {
    activity_customer: 'Customer',
    activity_affiliate: 'Affiliate',
    activity_brand: 'Brand',
    activity_date: 'Date',
    activity_ftd: 'FTD',
    activity_nrc: 'NRC',
    activity_ftd_amount: 'Ftd Amount',
    activity_deposits: 'Total Deposits',
    activity_deposits_count: 'Deposit Count',
    activity_withdrawals: 'Withdrawals',
    activity_cpa: 'CPA Cost',
    activity_country: 'Country',
    activity_casino_bets: 'Bets',
    activity_casino_wins: 'Wins',
    activity_casino_bonus: 'Bonus Cost',
    activity_casino_ngr: 'NGR',
    activity_casino_ggr: 'GGR',
    activity_casino_fees: 'Fees',
    activity_casino_rs: 'RS% Cost',
    activity_sports_bets: 'Sports Bets',
    activity_sports_wins: 'Sports Wins',
    activity_sports_bonus: 'Sports Bonus Cost',
    activity_sports_ngr: 'Sports NGR',
    activity_sports_ggr: 'Sports GGR',
    activity_sports_fees: 'Sports Fees',
    activity_sports_rs: 'Sports RS% Cost',
    customer_affiliate: 'Affiliate',
    customer_brand: 'Brand',
    customer_country: 'Customer Country',
    customer_external_id: 'Customer',
    customer_token: 'Token',
    customer_join_date: 'Customer Join Date',
    customer_external_status: 'Status',
    affiliate_email: 'Email',
    affiliate_username: 'Username',
    affiliate_name: 'Name',
    affiliate_phone: 'Phone number',
    affiliate_skype: 'Skype',
    affiliate_website: 'Website',
    affiliate_company_name: 'Company name',
    affiliate_traffic_source: 'Traffic Source',
    affiliate_join_date: 'Join Date',
    affiliate_status: 'Status'
};
exports.DefaultTableHeaders = {
    brandName: 'Brand',
    id: 'ID',
    country: 'Country',
    affiliateUsername: 'Affiliate',
    masterCostYield: 'Master Cost',
    affiliateLabel: 'Affiliate Status',
    trafficSource: 'Traffic Source',
    dpp: 'D-PP',
    cpp: 'C-PP',
    ppp: 'P-PP',
    profit: 'Profit',
    affiliateId: 'Aff ID',
    firstManagerUsername: '1st Manager',
    secondManagerUsername: '2nd Manager',
    campaign: 'Campaign',
    period: 'Period',
    uniqueClicks: 'Unique Clicks',
    nrc: 'NRC',
    ftd: 'FTD',
    sameMonthFtd: 'Same Month FTD',
    qualifiedFtds: 'Qualified FTDs',
    ftdAmount: 'FTD Amount',
    ftdDeposits: 'FTD TTL Deposits',
    multipleDepositsCustomers: 'FTDs with +1 Deposits',
    activeCustomers: 'Active Customers',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    cpaAmount: 'CPA Cost',
    ttlCost: 'TTL Cost',
    masterCost: 'Master Cost',
    balance: 'Balance',
    balanceCorrection: 'Balance Correction',
    wpp: 'W-PP',
    ngrpp: 'NGR-PP',
    rawrpp: 'RAW-RPP',
    externalCustomerStatus: 'Status',
    externalCustomerId: 'External ID',
    externalAffiliateClickId: 'Click ID',
    registeredAt: 'Join Date',
    ftdDate: 'FTD Date',
    affiliateName: 'Aff. Name',
    affiliateEmail: 'Aff. E-mail',
    companyWebsite: 'Website',
    businessModel: 'Business Model',
    expectedVolumes: 'Expected Volumes',
    paymentType: 'Payment Type',
    linkTitle: 'Tracking Link Title',
    clicks: 'Clicks',
    ctr: 'CTR%',
    rtd: 'RTD%',
    ctd: 'CTD%',
    ftddpp: 'FTD Avg Deposit',
    roi: 'ROI%',
    casinoBets: 'Casino Bets',
    casinoWins: 'Casino Wins',
    casinoBonusCost: 'Casino Bonus Cost',
    casinoGgr: 'Casino GGR',
    casinoNgr: 'Casino NGR',
    casinoFees: 'Casino Fees',
    casinoRsCost: 'Casino RS% Cost',
    sportsBets: 'Sports Bets',
    sportsWins: 'Sports Wins',
    sportsBonusCost: 'Sports Bonus Cost',
    sportsGgr: 'Sports GGR',
    sportsNgr: 'Sports NGR',
    sportsFees: 'Sports Fees',
    sportsRsCost: 'Sports RS% Cost',
    totalBets: 'Total Bets',
    totalWins: 'Total Wins',
    totalBonusCost: 'Total Bonus Cost',
    totalGgr: 'Total GGR',
    totalNgr: 'Total NGR',
    totalFees: 'Total Fees',
    totalRsCost: 'Total RS% Cost'
};
